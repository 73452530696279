import Chart from 'chart.js/auto';

const labels = [];
const dataPoints = [];

fetch('/api/counts')
    .then(response => response.json())
    .then(data => {
        data.forEach(count => {
            const date = new Date(count.created_at);
            const formattedDate = date.toISOString().split('T')[0];

            labels.push(formattedDate);
            dataPoints.push(count.count);
        });

        const dataC = {
            labels: labels,
            datasets: [{
                label: 'Ordini',
                backgroundColor: 'rgb(140, 195, 77)',
                borderColor: 'rgb(140, 195, 77)',
                data: dataPoints,
            }]
        };

        const config = {
            type: 'line',
            data: dataC,
            options: {}
        };

        new Chart(
            document.getElementById('myChart'),
            config
        );
    })
    .catch(error => {
        console.error('Error fetching data:', error);
    });
